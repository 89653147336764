<template>
  <article class="message is-danger" v-if="errors.length">
    <div class="message-header">
      <p>Upss.. mamy problem</p>
    </div>
    <div class="message-body">
      <ul v-if="errors.length > 1">
        <li v-for="(error, i) in errors" :key="i">- {{ error }}</li>
      </ul>
      <p v-else>{{ errors[0] }}</p>
    </div>
  </article>
</template>

<script>
export default {
  name: "BaseErrors",
  props: ['errors']
}
</script>

<style>

</style>
