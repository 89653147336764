<template>
<div class="container is-max-desktop">
  <div class="section">
    <BaseErrors :errors="errors" />

    <div v-if="redirect == '/organize'">
      <h1 class="title">Aby stworzyc losowanie, musisz sie zalogowac</h1>
      <p>Logowanie jest potrzebne, aby zabezpieczyc Twoje losowanie. Zadne prywatne dane nie sa wykorzystywane. Szczegoly w polityce prywatnosci.</p>
    </div>

    <h1 class="title" v-else>Logowanie</h1>

    <div class="buttons mt-5">
      <b-button @click="signInWithGoogle" expanded>Dolacz przez konto Google</b-button>
      <b-button @click="signInWithFacebook" expanded>Dolacz przez konto Facebook</b-button>
    </div>
  </div>
</div>
</template>

<script>
import { firebase, auth } from "../api/firebase";
import BaseErrors from "@/components/BaseErrors";

export default {
  components: { BaseErrors },
  computed: {
    redirect() {
      return this.$route.query.ref;
    },
  },
  data() {
    return { errors: [] };
  },
  methods: {
    signInWithFacebook() {
      return this.signInWith("facebook");
    },
    signInWithGoogle() {
      return this.signInWith("google");
    },
    signInWith(providerId = null) {
      this.errors = [];

      let provider = null;

      switch (providerId) {
        case "facebook":
          provider = new firebase.auth.FacebookAuthProvider();
          break;
        case "google":
          provider = new firebase.auth.GoogleAuthProvider();
          break;
      }

      if (!provider) {
        return this.errors.push("Przepraszamy. Sprobuj ponownie");
      }

      return auth
        .signInWithPopup(provider)
        .then((result) => this.$router.push({ path: this.redirect ?? "/my" }))
        .catch((error) => this.errors.push(error.message));
    },
  },
};
</script>
